import * as moment from 'moment';
import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-public-event',
  templateUrl: './public-event.component.html',
  styleUrls: ['./public-event.component.scss']
})
export class PublicEventComponent implements OnInit {

	params: any;
	response: any;
	eventDetails: any = {
		users: [],
		joined: 0,
		institute: ''
	}

	environment = environment;
	baseUrl = environment.baseUrl;
	apiLink = environment.apiUrl + '/api/';

	loading = false;

	constructor(
		private route: Router,
		private http: HttpClient,
		private activeRoute: ActivatedRoute
	) {
		this.activeRoute.params.subscribe(params => {
			this.params = params;
		});
	}

	ngOnInit(): void {
		this.setupEvent();
	}
	//status check
	isAuthenticated() {
		let authState: any = localStorage.getItem('AUTH_STATUS');
		if (!authState) return false;

		authState = parseInt(authState);
		return authState == 1;
	}
	async getUserDetails() {
		if (this.isAuthenticated()) {
			const type = localStorage.getItem('USER_TYPE');
			const token = localStorage.getItem('AUTH_TOKEN');
			const user: any = localStorage.getItem('USER_DATA');

			return {
				user: JSON.parse(user),
				token: token,
				type: type
			};
		}
		else {
			return {};
		}
	}

	formatDate(date:any) {
		return moment(date).format('Do MMM YYYY');
	}
	formatTime(time:any) {
		return moment(time, "HH:mm:ss").format("hh:mm A");
	}

	async setupEvent() {
    let data = JSON.parse(atob(this.params.event_id));
		let request = { id: data };
		this.loading = true;
    console.log('wawea',request);

		let userDetails = await this.getUserDetails(),
			token = userDetails.token ? userDetails.token : '';
		// Create header and append JWT token
		let headers = new HttpHeaders({
			'Authorization': `Bearer: ${token}`
		});
		headers.append('Authorization', token);

		return this.http.post(this.apiLink + 'event/get_public_details', request, { headers: headers }).toPromise().then(response => {
			this.response = response;
			this.loading = false;

			if (this.response.status == 0) {
				switch (userDetails.user.user_role) {
					case 2:
					case 3:
						this.route.navigate(['/dashboard/admin/events/']);
						break;

					case 5:
					case 6:
						this.route.navigate(['/dashboard/facilitator/events']);
						break;
				}
			} else {
				for (const key in this.response.event.users) {
					let user = this.response.event.users[key];
					user['name'] = user.reg_fname;
					user['name'] += (user.reg_mname && user.reg_mname.length > 0) ? ' ' + user.reg_mname : '';
					user['name'] += (user.reg_lname && user.reg_lname.length > 0) ? ' ' + user.reg_lname : '';
					this.response.event.users[key]['name'] = user['name'];
				}
				this.eventDetails = this.response.event;
			}
		});
	}

  async register() {
    alert("Please Login/Sign up to register into this event");
    this.route.navigate(['/auth/session/login']);
  }

/* 	async joinEvent() {
		this.loading = true;

		let userDetails = await this.getUserDetails(),
			token = userDetails.token ? userDetails.token : '';
		// Create header and append JWT token
		let headers = new HttpHeaders({
			'Authorization': `Bearer: ${token}`
		});
		headers.append('Authorization', token);

		let request = {
			event_id: this.params.event_id,
			user_id: userDetails.user.reg_id
		};

		this.response = await this.http.post(this.apiLink + 'event/register', request, { headers: headers }).toPromise();
		if(this.response && this.response.status == 1) this.eventDetails['joined'] = 1;
		let user = {
			name: userDetails.user.reg_fname,
			role_display_name: userDetails.user.role_display_name
		}
		user['name'] += (userDetails.user.reg_mname && userDetails.user.reg_mname.length > 0) ? ' ' + userDetails.user.reg_mname : '';
		user['name'] += (userDetails.user.reg_lname && userDetails.user.reg_lname.length > 0) ? ' ' + userDetails.user.reg_lname : '';
		this.loading = false;
	} */

}
