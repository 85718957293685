import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminServicesService } from 'src/app/modules/admin/services/admin-services.service';
import { FTeamViewComponent } from 'src/app/modules/facilitators/pages/f-teams/f-team-view/f-team-view.component';

@Component({
  selector: 'uikit-teams-single-view',
  templateUrl: './teams-single-view.component.html',
  styleUrls: ['./teams-single-view.component.scss']
})
export class TeamsSingleViewComponent implements OnInit {
  
  team_details :any=[];
  team_id:any;
  @ViewChild('fTeamView', { static: false }) fTeamView: FTeamViewComponent | undefined;
  constructor(private route:ActivatedRoute,private  api:AdminServicesService) { }

  ngOnInit(): void {
    this.route.params.subscribe((params:any) => {
      console.log((params)) 
      this.team_id = atob(params['id']);
      this.getTeamDetails(this.team_id)
    })
 
  }

  public getTeamDetails(team_id:any){

  this.api.getTeamDetails(team_id).subscribe(
    (res:any)=>{
      if(res['status']===1){
      
        this.team_details = res['details']
        console.log(this.team_details.team_name)
        console.log(this.team_details)
        this.fTeamView?.updateTeamName(this.team_details.team_nam)
      }
      else{
        alert(res['msg'])
      }
    },
    err=>{
      alert(err['msg'])
    }
    
  )
   
    

  }

}
