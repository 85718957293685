import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminServicesService {
  apiLink = environment.apiUrl + '/api/';

  constructor(private http: HttpClient) {

  }


  //getting  user token from localstorage
  get_token() {
    return localStorage.getItem('AUTH_TOKEN');
  }
  // getting user association_is from localstorage
  get_association_id() {
    let user: any = localStorage.getItem('USER_DATA');
    user = JSON.parse(user)
    return user['association_id']

  }
  get_associations() {
    let user: any = localStorage.getItem('USER_DATA');
    user = JSON.parse(user)
    return user['all_associations']

  }
  get_national_ass() {
    let user: any = localStorage.getItem('USER_DATA');
    user = JSON.parse(user)
    return user['national_ass']

  }

  // get User role
  get_user_role(){
    let user: any = localStorage.getItem('USER_DATA');
    user = JSON.parse(user)
    return user['user_role']
  }


  // publishing post
  publishPost(data: any) {

    // getting association_id
    data.ass_id = this.get_association_id();

    let token: any = this.get_token();
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });

    return this.http.post(this.apiLink + 'post/create', data, { headers: headers })
  }

  // Update post
  updatePost(data: any) {
    // getting association_id
    data.ass_id = this.get_association_id();

    let token: any = this.get_token();
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });

    return this.http.post(this.apiLink + 'post/update', data, { headers: headers })
  }

  // getting all posts
  getAllPosts() {
    let ass_id = this.get_associations();

    let token: any = this.get_token();
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    return this.http.post(this.apiLink + 'post/getAllPosts', { ass_id: ass_id }, { headers: headers })
  }

  // Update post
  deletePost(data: any) {
    // getting association_id
    // data.ass_id = this.get_association_id();

    let token: any = this.get_token();
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });

    return this.http.post(this.apiLink + 'post/delete', {post_id:data}, { headers: headers })
  }

  // getting Association Posts
  getAssociationPosts() {

    let ass_id = this.get_national_ass()

    
    let token: any = this.get_token();
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    return this.http.post(this.apiLink + 'post/getNationalPosts', { ass_id: ass_id }, { headers: headers })
  }


  // getting all age groups 
  getAgeGroups() {

    let token: any = this.get_token();


    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    return this.http.get(this.apiLink + 'global/age_group', { headers: headers })
  }

  createTeam(data: any) {

    let token: any = this.get_token();

    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    return this.http.post(this.apiLink + 'team/create', data, { headers: headers })
  }


  getAllTeam() {

    let token: any = this.get_token();


    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    return this.http.post(this.apiLink + 'team/get_all_team', {}, { headers: headers })
  }

  getTeamDetails(id:any) {

    let token: any = this.get_token();

    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    return this.http.post(this.apiLink + 'team/get_team_details', {team_id:id}, { headers: headers })
  }


  get_institution_types() {

    return this.http.post(this.apiLink + 'global/get_global_inst_type', {})
  }

  get_session_types() {
    
    let token: any = this.get_token();

    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });

    return this.http.get(this.apiLink + 'global/session_type', { headers: headers })
  }

  get_Regions() {
    return this.http.post(this.apiLink + 'global/get_global_region', {})
  }
   

  get_InstituteName() {

    let token: any = this.get_token();
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });

    return this.http.post(this.apiLink + 'team/get_institute', {},{ headers: headers })
  }

  // createsession
CreateSession(data: any) {



    let token: any = this.get_token();
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });

    return this.http.post(this.apiLink + 'team/create_session', data, { headers: headers })
  }
  getSessions(team_id: any) {



    let token: any = this.get_token();
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });

    return this.http.post(this.apiLink + 'team/get_all_session', {team_id:team_id}, { headers: headers })
  }

}
