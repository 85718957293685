import { Component, OnInit } from '@angular/core';
import { SideBarItemsPrototype } from 'src/app/prototypes/sidebarItems.interface';

@Component({
  selector: 'app-side-nav-dashboard-layout',
  templateUrl: './side-nav-dashboard-layout.component.html',
  styleUrls: ['./side-nav-dashboard-layout.component.scss']
})
export class SideNavDashboardLayoutComponent implements OnInit {

  user = 'facilitator';
  userDetails:any = {
    type: ''
  }

  sidebarNaAdmin: SideBarItemsPrototype[] = [
    {
      label: 'Home',
      link: '/dashboard/admin/home',
      icon: 'home',
    },
    // {
    //   label: 'Associations',
    //   link: '/dashboard/admin/associations',
    //   icon: 'teams',
    // },
    {
      label: 'Posts',
      link: '/dashboard/admin/posts',
      icon: 'posts',
    },
    {
      label: 'Events',
      link: '/dashboard/admin/events',
      icon: 'events',
    },
    {
      label: 'Institutes',
      link: '/dashboard/admin/institute',
      icon: 'people',
    },
    {
      label: 'Calendar',
      link: '/dashboard/admin/calendar',
      icon: 'calendar',
    },
    // {
    //   label: 'Surveys',
    //   link: '/dashboard/admin/surveys',
    //   icon: 'surveys',
    // },
    // {
    //   label: 'Tournaments',
    //   link: '/dashboard/admin/tournaments',
    //   icon: 'tournaments',
    // },
    {
      label: 'Profile',
      link: '/dashboard/admin/profile',
      icon: 'user',
    },
    {
      label: 'Password Change',
      link: '/dashboard/admin/password',
      icon: 'user',
    },
  ]

  sidebarRaAdmin: SideBarItemsPrototype[] = [
    {
      label: 'Home',
      link: '/dashboard/admin/home',
      icon: 'home',
    },
    // {
    //   label: 'Associations',
    //   link: '/dashboard/admin/associations',
    //   icon: 'teams',
    // },
    {
      label: 'Posts',
      link: '/dashboard/admin/posts',
      icon: 'posts',
    },
    {
      label: 'Events',
      link: '/dashboard/admin/events',
      icon: 'events',
    },
    {
      label: 'Institutes',
      link: '/dashboard/admin/institute',
      icon: 'people',
    },
    {
      label: 'Calendar',
      link: '/dashboard/admin/calendar',
      icon: 'calendar',
    },
    // {
    //   label: 'Surveys',
    //   link: '/dashboard/admin/surveys',
    //   icon: 'surveys',
    // },
    // {
    //   label: 'Tournaments',
    //   link: '/dashboard/admin/tournaments',
    //   icon: 'tournaments',
    // },
    {
      label: 'Profile',
      link: '/dashboard/admin/profile',
      icon: 'user',
    },
    {
      label: 'Password Change',
      link: '/dashboard/admin/password',
      icon: 'user',
    },
  ]

  sidebarOther: SideBarItemsPrototype[] = [
    {
      label: 'Home',
      link: '/dashboard/facilitator/home',
      icon: 'home',
    },
    {
      label: 'Teams',
      link: '/dashboard/facilitator/teams',
      icon: 'teams',
    },
    {
      label: 'Events',
      link: '/dashboard/facilitator/events',
      icon: 'events',
    },
    {
      label: 'Posts',
      link: '/dashboard/facilitator/posts',
      icon: 'posts',
    },
    // {
    //   label: 'Surveys',
    //   link: '/dashboard/facilitator/surveys',
    //   icon: 'surveys',
    // },
    // {
    //   label: 'Tournaments',
    //   link: '/dashboard/facilitator/tournaments',
    //   icon: 'tournaments',
    // },
    {
      label: 'People',
      link: '/dashboard/facilitator/people',
      icon: 'user',
    },
    {
      label: 'Calendar',
      link: '/dashboard/facilitator/calendar',
      icon: 'calendar',
    },
    {
      label: 'Profile',
      link: '/dashboard/facilitator/profile',
      icon: 'user',
    },
    {
      label: 'Password Change',
      link: '/dashboard/facilitator/password',
      icon: 'user',
    },
  ]


  sideBar2Admin: SideBarItemsPrototype[] = [
    // {
    //   label: 'Notifications',
    //   link: '/notifications',
    //   icon: 'notifications',
    // },
    {
      label: 'Manage Users',
      link: '/dashboard/admin/users',
      icon: 'people',
    }
  ]

  sideBar2Other: SideBarItemsPrototype[] = [
    // {
    //   label: 'Notifications',
    //   link: '/notifications',
    //   icon: 'notifications',
    // },
    // {
    //   label: 'Manage Users',
    //   link: '/dashboard/admin/users',
    //   icon: 'user',
    // }
  ]

  sideBarplayer: SideBarItemsPrototype[] = [
    {
      label: 'Home',
      link: '/dashboard/player/home',
      icon: 'home',
    },
    {
      label: 'Posts',
      link: '/dashboard/player/posts',
      icon: 'posts',
    },
    {
      label: 'Events',
      link: '/dashboard/player/events',
      icon: 'events',
    },
    {
      label: 'Profile',
      link: '/dashboard/player/profile',
      icon: 'user',
    }
  ]

  mainSidebar: SideBarItemsPrototype[] = [];
  secondarySidebar: SideBarItemsPrototype[] = [];

  constructor() { }

  async ngOnInit() {
    let userDetails = await this.getUserDetails();
    this.userDetails = userDetails;
    switch (userDetails.type) {
      case 'national':
        this.mainSidebar = this.sidebarNaAdmin;
        this.secondarySidebar = this.sideBar2Admin;
      break;
      case 'regional':
        this.mainSidebar = this.sidebarRaAdmin;
        this.secondarySidebar = this.sideBar2Admin;
      break;

      case 'coach':
      case 'parents':
      case 'facilitator':
        this.mainSidebar = this.sidebarOther;
        this.secondarySidebar = this.sideBar2Other;
      break;
      case 'player':
        this.mainSidebar = this.sideBarplayer;
        this.secondarySidebar = this.sideBar2Other;
      break;
    }
  }

  //status check
  isAuthenticated() {
    let authState:any = localStorage.getItem('AUTH_STATUS');
    if(!authState) return false;

    authState = parseInt(authState);
    return authState == 1;
  }
  async getUserDetails() {
    if(this.isAuthenticated()) {
      const type = localStorage.getItem('USER_TYPE');
      const token = localStorage.getItem('AUTH_TOKEN');
      const user:any = localStorage.getItem('USER_DATA');
      return {
        user: JSON.parse(user),
        token: token,
        type: type
      };
    }
    else {
      return {};
    }
  }

  async logout() {
    localStorage.removeItem('USER_DATA');
    localStorage.removeItem('USER_TYPE');
    localStorage.removeItem('AUTH_TOKEN');
    localStorage.removeItem('AUTH_STATUS');
    
    window.location.reload();
  }

}
