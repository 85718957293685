<nav class="sideNav">
    <div class="branding ptr pbr pll prl bdrb(383C40)  ">
        <a [routerLink]="['/']">
            <img src="../../../assets/images/logos/Logo.svg" alt="verdentrum" class="brandingImg wdt(100)">
        </a>
    </div>

    <div class="navItems ">
        <!-- Main Sidebar -->
        <ul class="nav navItemsUl bdrb(383C40) pr">
            <li class="navItemWrapper wdt(100) mbs" *ngFor="let navItem of mainSidebar">
                <a [routerLink]="[navItem.link]" routerLinkActive="navLinkActive" class="navLinkInActive">
                    <button mat-button class="navItem wdt(100)">
                        <mat-icon class="icon mrr" [svgIcon]="navItem.icon"></mat-icon>
                        <span class="navItemLabel ff-sb fc(C3C5C7) "> {{navItem.label}}</span>
                    </button>
                </a>
            </li>
            <li class="wdt(100) mbs" *ngIf="userDetails.type == 'national'">
                <a [href]="'https://dashboard.verdentum.org/superset/dashboard/uefa/?standalone=true'" target="_blank">
                    <button mat-button class="navItem wdt(100)">
                        <mat-icon class="icon mrr" svgIcon="events"></mat-icon>
                        <span class="navItemLabel ff-sb fc(C3C5C7) "> Dashboard</span>
                    </button>
                </a>
            </li>
        </ul>

        <!-- Secondary Sidebar -->
        <ul class="nav navItemsUl bdrb(383C40) pr">
            <li class="navItemWrapper wdt(100) mbs" *ngFor="let navItem of secondarySidebar">
                <a [routerLink]="[navItem.link]" routerLinkActive="navLinkActive" class="navLinkInActive">
                    <button mat-button class="navItem wdt(100)">
                        <mat-icon class="icon mrr" [svgIcon]="navItem.icon"></mat-icon>
                        <span class="navItemLabel ff-sb fc(C3C5C7) "> {{navItem.label}}</span>
                    </button>
                </a>
            </li>
            <!-- Logout Menu -->
            <li class="navItemWrapper wdt(100) mbs">
                <a class="navLinkInActive" href="javascript:void(0)">
                    <button mat-button class="navItem wdt(100)" (click)="logout()">
                        <mat-icon class="icon mrr" svgIcon="logout"></mat-icon>
                        <span class="navItemLabel ff-sb fc(C3C5C7)">Logout</span>
                    </button>
                </a>
            </li>
        </ul>
    </div>
</nav>