<section class="uiComponent postsListView bgc(161F26) bgshadow1 bdr-rounded">
    <div class="componentWrapper ">
        <div class="componentHeader bdrb pr">
            <div class="row">
                <div class="col-6 align-self-center">
                    <h2 class="componentTitle fs(16) fc(ffffff) ff-sb m-unset"> Team Details</h2>
                </div>
                <div class="col-6  " align="right">
                    <!-- <div class="post-actions uiComponent-top-actions align-items-start">                        
                        <mat-icon class=" fc(82898E) uiComponent-icon-action hgta mlr">add</mat-icon>
                    </div> -->
                </div>
            </div>
        </div>

        <div class="componentBody ptr plr prr pbl">
            <div class="teams  ">
                <div class="team" >
                    <!-- <app-teams-element></app-teams-element> -->
                    <div class="row">
                        <div class="col" >
                          <div class="team" >
                            <img src="../../../../../assets/images/others/event_2.jpg" alt="" class="teambanner rounded wdt(100)">
                            <p class="teamName ff-r fs(14) fc(EDEEEF) mtr"> {{team_details.team_name}}</p>
                            <div class="d-inline-flex">
                                <p *ngIf="team_details.gender == 1" class="teamName ff-r fs(14) fc(EDEEEF) mts">Male,</p>
                                <p *ngIf="team_details.gender == 2" class="teamName ff-r fs(14) fc(EDEEEF) mts">Female - </p>
                                <p class="teamName ff-r fs(14) fc(EDEEEF) mts" >&nbsp;Age  {{team_details.age_min}} - {{team_details.age_max}}</p>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>