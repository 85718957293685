export enum Icons {
  FilterIcon = 'filter',
  CalendarIcon = 'calendar',
  LocationIcon = 'location',
  threeHorizontalDots = 'horizontalDots',
  copyIcon = 'copy',
  homeIcon = 'home',
  menuIcon = 'menu-open',
  PlayingFootBallIcon = 'football-player',
  footBallIcon = 'footBall',
  eventsIcon = 'events',
  coachIcon = 'coach',
  teamsIcon = 'teams',
  postsIcon = 'posts',
  surveysIcon = 'surveys',
  tournamentsIcon = 'tournaments',
  peopleIcon = 'people',
  notificationsIcon = 'notifications',
  userIcon = 'user',
  logoutIcon = 'logout',
  timeIcon = 'time',
  websiteIcon = 'website',
  linkedinIcon = 'linkedin',
  facebookIcon = 'facebook',
  matTabCompleteCheckIcon = 'tabCompleteCheck',
  crossCirclIcon = 'crossCircle',
  tickCircleIcon = 'tickCircle',
  listviewIcon = 'listView',
  chartViewIcon = 'chartView',
  calenderWeek = 'calender-week',
  taskDone = 'weekcheck',
  taskNotDone = 'weekcross',
  flag = 'landingFlag',
  events = 'landingEvents',
  trophy = 'trophy',
  pic = 'Headshot',
  quote = 'quotes',
  rearrangeIcon = 'rearrange',
  pencilIcon = 'pencil',
  addIcon = 'add',
  back = 'back',
  imgIcon = 'Img',
  usersIcon = 'users',
  lightLogo = 'lightLogo',
  photoIcon = 'photo',
  instagram = 'instagram',
  twitter = 'twitter',
  darkFacebook = 'darkFacebook',
  footballPlayerIcon = 'footballPlayer',
}
