<section class="createNewEventWrapper bgc(0F171D)">
    <nav class="landingNav pr bdrb(383C40)">
        <div class="">
            <a [routerLink]="['/']">
                <img src="../../../assets/images/logos/Logo.svg" alt="verdentrum" class="wdt(100)" />
            </a>
        </div>
    </nav>
    <div class="container-fluid">
      <div class="ptl plr pbl prr">
        <div class="eventHeading bdrb pbr">
          <h2 class="eventRegion text-upper fc(C3C5C7) ff-sb fs(16)">
            {{ eventDetails.institute }}
          </h2>
          <h1 class="eventName fc(ffffff) ff-sb fs(24) m-unset">
            {{ eventDetails.event_name }}
          </h1>
        </div>
        
        <div class="eventDescriptionWrapper mtl">
          <div class="d-flex justify-content-between align-items-center">
            <h1 class="eventName fc(ffffff) ff-sb fs(24) m-unset">
              Event Description
            </h1>
            <button type="button" class="btn-dialog-action ff-sb" color="accent" (click)="register()" mat-raised-button >
              Register
            </button>
          </div>
        </div>
        
        <div class="eventDetailsWrapper mtr">
          <div class="d-flex justify-content-start align-items-center">
            <p class="eventDate fs(16) fc(EDEEEF) mrl">
              <mat-icon svgIcon="calendar" class="uiComponent-small-icon mrs"></mat-icon>
              {{ formatDate(eventDetails.start_date) }} {{ formatTime(eventDetails.start_time) }}
            </p>
            <p class="eventTime fs(16) fc(EDEEEF) mrl">
              <mat-icon svgIcon="calendar" class="uiComponent-small-icon mrs"></mat-icon>
              {{ formatDate(eventDetails.end_date) }} {{ formatTime(eventDetails.end_time) }}
            </p>
            <p class="eventTime fs(16) fc(EDEEEF) mrl">
              <mat-icon
                svgIcon="location"
                class="uiComponent-small-icon"
              ></mat-icon>
              {{ eventDetails.event_venue }}
              <!-- <a href="" class="fc(77B7FD) mlxs">Google Maps</a> -->
            </p>
          </div>
        </div>
        
        <!-- <div class="eventInfoWrapper mtl">
          <img [src]="environment.apiUrl + '/uploads/' + eventDetails.event_image" class="wdt(100) mtr mbr" height="300"/>
          <p class="fs(16) fc(EDEEEF) text-justify">
            {{ eventDetails.event_summary }}
          </p>
        </div> -->
        <div class="eventInfoWrapper mtl">
          <div style="height:300px;width:100%;overflow:hidden;" class="mtr mbr">
              <img class="wdt(100)" [src]="environment.apiUrl + '/uploads/' + eventDetails.event_image">
          </div>
          <p class="fs(16) fc(EDEEEF) text-justify">{{ eventDetails.event_summary }}</p>
        </div>
        
        <h1 class="registration fc(ffffff) ff-sb fs(20) mtxl">
          Registration End
        </h1>
        <div class="registrationEndDetails mtr">
          <div class="d-flex justify-content-start align-items-center">
            <p class="endDate fs(16) fc(EDEEEF) mrl">
              <mat-icon svgIcon="calendar" class="uiComponent-small-icon mrs"></mat-icon>
              {{ formatDate(eventDetails.reg_end_date) }}
            </p>
            <p class="endTime fs(16) fc(EDEEEF) mrl">
              <mat-icon svgIcon="time" class="uiComponent-small-icon mrs"></mat-icon>
              {{ formatTime(eventDetails.reg_end_time) }}
            </p>
          </div>
        </div>
        
        <div class="invitees mtxl">
          <h1 class="title fc(ffffff) ff-sb fs(20)">Invitees</h1>
          <div class="divisionsGroup d-flex justify-content-start align-items-center">
            <div class="category ff-sb bdr-rounded fs(16) mrl" *ngFor="let inv of eventDetails.invitees" style="cursor:pointer;">
              {{ inv.invitee_name }}
            </div>
          </div>
        </div>
        
        <div class="divisions mtxl">
          <h1 class="title fc(ffffff) ff-sb fs(20) mbs">Divisions</h1>
          <div class="divisionsGroup d-flex justify-content-start align-items-center">
            <div class="category ff-sb bdr-rounded fs(16) mrl" *ngFor="let cat of eventDetails.categories" style="cursor:pointer;">
              {{ cat.name }}
            </div>
          </div>
        </div>
  
        <!-- <div class="invitees mtxl">
          <h1 class="title fc(ffffff) ff-sb fs(20)">Registered Users</h1>
          <div class="table-responsive">
            <table class="table" style="color:#FFFFFF;">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Role</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let user of eventDetails.users">
                  <td>{{ user.name }}</td>
                  <td>{{ user.role_display_name }}</td>
                </tr>
                <tr *ngIf="eventDetails.users.length === 0">
                  <td class="text-center" colspan="2">
                    <h5>No one has registered for the event yet.</h5>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div> -->
      </div>
    </div>
  </section>
  